<template>
  <div>
    <Modal
      ref="modal"
      classes="white"
      :full="false"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
    >
      <template #header>
        <font-awesome-icon icon="money-bill" />
        Payments Profile
      </template>
      <template #body>
        <CounterpartyOnboarding
          @close="onClose"
          @success="onSuccess"
          @add-owner="onOwnerOnboardingSuccess"
          @owner-onboarding="onShowOwnerOnboarding"
          :owners="owners"
        />
      </template>
    </Modal>
    <Modal
      ref="ownerModal"
      classes="white"
      :full="false"
      :click-away="false"
      :closeable="true"
      :scrollable="true"
      :size="'md'"
    >
      <template #header>
        <font-awesome-icon icon="user" fixed-width />
        Decision Makers
      </template>
      <template #body>
        <OwnerOnboarding
          @close="() => $refs.ownerModal.close()"
          @success="onOwnerOnboardingSuccess"
          :owner="owner"
        />
      </template>
    </Modal>
  </div>
</template>
<script>
import CounterpartyOnboarding from '../payments/counterparty/onboarding/CounterpartyOnboarding.vue'
import OwnerOnboarding from '../payments/counterparty/onboarding/OwnerOnboarding.vue'
import OwnerMixin from '../payments/counterparty/OwnerMixin'
import eventBus from '../../eventBus'

export default {
  name: 'CompanyOnboarding',
  methods: {
    onToggleCompanyOnboarding() {
      this.$refs.modal.open()
    }
  },
  mounted() {
    eventBus.$on('toggleCompanyOnboarding', this.onToggleCompanyOnboarding)
  },
  beforeUnmount() {
    eventBus.$off('toggleCompanyOnboarding', this.onToggleCompanyOnboarding)
  },
  components: {
    CounterpartyOnboarding,
    OwnerOnboarding
  },
  mixins: [OwnerMixin]
}
</script>
